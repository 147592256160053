<template>
  <div class="menu-body" v-if="$oidc.isAuthenticated">
    <Loader v-if="showLoader" />
    <Breadcrumb />
    <div class="main-con">
      <div class="upper-grey-block">
        <div class="top-title flex-block">
          <div>Selected List of Courses</div>
          <b-button v-if="!forEdit" pill variant="outline-secondary" class="save-button primary-color"
            @click="$router.push('/assignlearning')">
            <span style="font: 1.6rem 'BarlowSb', sans-serif">+</span> Add Course
          </b-button>
        </div>
        <hr />
        <div v-if="flag == 'Assign'">
          <div v-for="(course, pos) in courseDetails" :key="course.itemID">
            <div class="flex-block" style="padding: 1.5rem 0">
              <div>
                <div style="color: #000">
                  {{ course.itemTitle }}
                  <!-- Plant Optimization Governance Training -->
                </div>
                <div>
                  <b-button class="primar-color details-btn" v-b-modal.Messagebox
                    @click="detail_id = course.itemID, messagePopUpFunc()">
                    Details >
                  </b-button>
                </div>
              </div>
              <div style="width: 55%; justify-content: flex-end" class="flex-block">
                <div class="plant-dd" style="margin-left: 10px">
                  <label for="assignmentType" class="form-label select-lable lable-text">
                    Assignment Date
                  </label>

                  <b-input-group>
                    <b-form-input id="plant-associate-feedback-start-date-input" v-model="course.assignmentDateInput"
                      type="text" placeholder="Assignment Date" autocomplete="off" class="input-target-completion-date"
                      disabled></b-form-input>
                    <b-input-group-append>
                      <b-form-datepicker id="plant-associate-feedback-start-date-setting" v-model="course.assignmentDate"
                        button-only right locale="en-US" aria-controls="plant-associate-feedback-start-date-input" dark
                        :min="new Date()" label-no-date-selected="Assignment Date" :date-format-options="{
                            month: '2-digit',
                            day: '2-digit',
                            year: 'numeric',
                          }" @context="onContextA($event, pos)">
                      </b-form-datepicker>
                    </b-input-group-append>
                  </b-input-group>
                </div>
                <div class="plant-dd" style="margin-left: 10px">
                  <label for="assignmentType" v-if="!forEdit" class="form-label select-lable lable-text">
                    Required Date
                  </label>
                  <label for="assignmentType" v-if="forEdit" class="form-label select-lable lable-text">
                    Completion Date
                  </label>
                  <b-input-group>
                    <b-form-input id="plant-associate-feedback-start-date-input" v-model="course.completionDateInput"
                      type="text" placeholder="Required Date" autocomplete="off" class="input-target-completion-date"
                      disabled></b-form-input>
                    <b-input-group-append>
                      <b-form-datepicker id="plant-associate-feedback-start-date-setting" v-model="course.completionDate"
                        button-only right locale="en-US" aria-controls="plant-associate-feedback-start-date-input" dark
                        :min="new Date()" label-no-date-selected="Completion Date" :date-format-options="{
                            month: '2-digit',
                            day: '2-digit',
                            year: 'numeric',
                          }" @context="onContext($event, pos)">
                      </b-form-datepicker>
                    </b-input-group-append>
                  </b-input-group>
                </div>
                <b-button style="margin-left: 10px" v-if="!forEdit" pill variant="outline-secondary"
                  class="save-button primary-color" @click="removeCourse(pos)">
                  Remove
                </b-button>
              </div>
            </div>
            <hr />
          </div>
        </div>
        <div v-if="flag == 'Edit'">
          <div class="flex-block" v-for="course in courseDetails" :key="course.componentId" style="margin-bottom: 10px">
            <div>
              <div style="color: #000">
                {{ course.title }}
                <!-- Plant Optimization Governance Training -->
              </div>
              <div>
                <b-button class="primar-color details-btn" v-b-modal.Messagebox>
                  Details >
                </b-button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div style="padding: 2rem" class="flex-block">
        <div style="width: 30%">
          <div class="top-title font-16px">Suggested List of Members</div>
          <div class="">
            Please Add/Remove members from below list.
            <span class="member-count" v-if="addedMember">{{ addedMember }}
            </span>
          </div>
        </div>
        <b-modal id="action-filter" ref="my-modal" :hide-header="true" :hide-footer="true" dialog-class="right">
          <action-item-filter :hideModal="hideModal" :noOfFilters="noOfFilters" :filterArray="filterArray"
            :applyFilter="applyFilter" :resetFilter="resetFilter" :changedFilter="changedFilter"
            :filterSelected="filterSelected" :onSelect="onSelect" :onRemove="onRemove" />
        </b-modal>
        <div class="funnel-search-box">
          <div class="funnel-con" v-if="false">
            <div class="action-btn primary-color funnel-con" v-b-modal.action-filter v-if="forEdit">
              <b-icon icon="funnel"></b-icon>
            </div>
          </div>
          <search-user :user="items" id="search-user" @userSelected="afterSelecting($event)" />
        </div>
      </div>
      <div style="padding: 2rem">
        <b-table id="owner-list-table" class="gn-table-sm" responsive :items="items" :fields="fields" :per-page="perPage"
          :current-page="currentPage" :sort-by.sync="actionItemsSortBy" :sort-desc.sync="actionItemsSortDesc">
          <template #cell(sno)="row">
            {{ row.index + 1 }}
          </template>
          <template #cell(courseTaken)="row">
            {{ showCount(row) }}
          </template>
          <template #cell(action)="row">
            <b-button pill variant="outline-secondary" class="save-button primary-color"
              @click="addUser(row.item, row.index)" :disabled="row.item.added">
              Add
            </b-button>
          </template>
        </b-table>
        <hr class="below-table-hr" v-if="items.length !== 0" />
        <div v-if="items.length > 3" class="row pagination-con flex-block" style="padding: 0 2rem">
          <div class="col-lg-4 col-md-4 col-sm-12 record-info" style="padding: 0">
            {{ showingData }}
          </div>

          <div class="col-lg-6 col-md-6 col-sm-12" style="padding: 0">
            <b-pagination class="pagination-align record-info" v-model="currentPage" :total-rows="items.length"
              :per-page="perPage" aria-controls="my-table" prev-text="Back" next-text="Next" first-text="First"
              last-text="Last" @click.native="nextPageData(currentPage)"></b-pagination>
          </div>
          <div class="col-lg-2 col-md-2 col-sm-12 per-page-cont record-info">
            <select v-model="perPage" class="per-page" aria-label="Default select example">
              <option v-if="this.items.length > 5" :value="5">5</option>
              <option v-if="this.items.length > 10" :value="10">10</option>
              <option v-if="this.items.length > 50" :value="50">50</option>
              <option else :value="this.items.length">
                {{ this.items.length }}
              </option>
            </select>
            Per page
          </div>
        </div>
        <div class="no-data" v-if="items.length === 0">
          <img alt="no data" src="../../assets/No_Data.svg" />
          <span class="ed-date">No Records Found</span>
        </div>
        <div v-if="addedUser.length">
          <div class="font-16px top-title">Added Members</div>
          <div class="flex-block" style="justify-content: flex-start">
            <div v-for="(user, index) in addedUser" :key="user.userId" class="user-bottom-list">
              <div style="padding: 0.5rem 1rem">
                {{ user.name }}
              </div>
              <div @click="removeUser(user, index)" style="cursor: pointer; padding: 0.5rem 1rem">
                X
              </div>
            </div>
          </div>
        </div>
        <hr class="below-table-hr" v-if="addedUser.length" />
        <div class="add-action-footer-info">
          <b-button class="discard-btn" size="lg" @click="discardAction()">
            Reset
          </b-button>
          <b-button v-b-modal.messagePopup pill class="primar-btn submit-btn" size="lg"
            @click="assignOrEditCourseToTeam()">
            Confirm
          </b-button>
        </div>
      </div>
    </div>
    <MessagePopup :routToBack="true" :message="errorMsg" :show="showPopup" :headText="headText" />
    <Messagebox class="alert" :dataObj="popUpObj" />
  </div>
</template>
<script>
import Breadcrumb from "@/components/common/Breadcrumb.vue";
import Loader from "@/components/common/Loader.vue";
import SearchUser from "../Profile/SearchUser.vue";
import Messagebox from "./messagebox.vue";
import ActionItemFilter from "@/components/Dashboard/ActionItemFilter.vue";
import api from "@/common/constants/api";
import apiCalls from "@/common/api/apiCalls";
import MessagePopup from "@/components/common/MessagePopup.vue";
import moment from "moment";

export default {
  name: "EditEducation",
  props: { item: Array, studentsId: Array, flag: String },
  data() {
    return {
      detail_id: '',
      showLoader: true,
      forEdit: true,
      assignmentType: "",
      assignmentDateInput: null,
      assignmentDate: null,
      completionDateInput: null,
      completionDate: null,
      perPage: 5,
      currentPage: 1,
      actionItemsSortBy: "name",
      addedUser: [],
      actionItemsSortDesc: false,
      courseDetails: [],
      showPopup: false,
      errorMsg: "Please add at least one user to proceed with this request.",
      headText: "",
      itemid: "",
      fields: [
        {
          key: "sno",
          sortable: false,
          label: "#",
        },
        {
          key: "name",
          label: "Name",
          sortable: false,
        },
        {
          key: "courseTaken",
          label: "Course Taken",
          sortable: false,
          tdClass: (value) => {
            if (this.flag == "Edit") {
              return "d-none";
            }
          },
          thClass: (value) => {
            if (this.flag == "Edit") {
              return "d-none";
            }
          },
        },
        {
          key: "action",
          label: "",
          sortable: false,
        },
      ],
      items: [],
      popUpObj: {
        id: "",
        imgScr: "",
        title: "",
        description: "",
        shortName: '',
      },

      noOfFilters: 4,
      filterArray: [],
      filterSelected: [],
    };
  },
  components: {
    Loader,
    Breadcrumb,
    SearchUser,
    Messagebox,
    ActionItemFilter,
    MessagePopup,
  },
  beforeCreate() {
    this.$store.dispatch("selectedMenu", {
      main: 7,
    });
    this.$store.dispatch("breadcrumb", {
      title: "Education",
      breadcrumb: [
        { name: "Home / ", primary: true, redirect: "/dashboard" },
        {
          name: "Eduction ",
          redirect: "/education",
          primary: true,
        },
      ],
    });
  },
  created() {
    if (!this.item) {
      this.$router.push({
        name: "AssignLearning",
      });
    } else {
      this.getUserList();
      this.courseDetails = this.item;
      this.courseDetails.forEach((element) => {
        element["assignmentDateInput"] = null;
        element["assignmentDate"] = null;
        element["completionDateInput"] = null;
        element["completionDate"] = null;
      });
      if (this.flag == "Assign") {
        this.forEdit = false;
      } else {
        this.messagePopUpFunc();
        this.forEdit = true;
      }
      this.fields[2].label = this.forEdit ? "" : "Course Taken";
      this.filterArray = [
        {
          labelName: "Category",
          arrayValue: ["XYZ", "ABC"],
          id: "Category",
        },
      ];
    }
  },
  computed: {
    showingData() {
      let showData = this.perPage * this.currentPage;
      let showFirstData = showData - this.perPage;
      if (showData > this.items.length) {
        showData = this.items.length;
      }
      showFirstData = showFirstData + 1;
      return (
        "Showing " +
        showFirstData +
        " to " +
        showData +
        " of " +
        this.items.length +
        " records"
      );
    },
    addedMember() {
      if (this.addedUser.length < 2) {
        return this.addedUser.length + " Member Added";
      } else {
        return this.addedUser.length + " Members Added";
      }
    },
  },
  methods: {
    messagePopUpFunc() {
      if (this.flag == "Edit") {
        this.popUpObj.id = this.courseDetails[0].componentId;
        this.popUpObj.imgScr = this.courseDetails[0].imageUrl;
        this.popUpObj.title = this.courseDetails[0].title;
        this.popUpObj.description = this.courseDetails[0].description;
        this.popUpObj.shortName = this.courseDetails[0].shortName;
      } else {
        let detailPopUp = [];
        let ids = "";
        let popUp = {
          id: "",
          imgScr: "",
          title: "",
          description: "",
          shortName: '',
        };
        for (const element of this.courseDetails) {
          if (this.detail_id == element.itemID) {
            popUp.id = element.itemID;
            popUp.imgScr = element.imageUrl;
            popUp.title = element.itemTitle;
            popUp.description = element.description;
            popUp.shortName = element.shortName;
          }

          detailPopUp.push(popUp);
        }
        for (const element of detailPopUp) {
          ids = element.id;
          if (ids == this.detail_id) {
            this.popUpObj = element;
          }
        }
      }
    },
    showCount(row) {
      if (this.flag == "Assign") {
        let str = ''
        if (row.item.courseTaken)
          str = row.item.courseTaken + " out of " + this.courseDetails.length +
            (row.item.courseTaken == 1 ? " course is " : " courses are ") + "already assigned."
        else str = "-";
        return str;
      } else {
        return "";
      }
    },
    removeCourse(pos) {
      let index = pos;
      if (index != -1) {
        this.courseDetails.splice(index, 1);
      }
    },
    getUserList() {
      apiCalls.httpCallDetails(api.GETLMSUSERTABLELIST, "get").then((data) => {
        // console.log(data);
        //  {
        //   userId: "1",
        //   added: false,
        //   name: "Chetan",
        // },
        this.items = [];
        data.data.forEach((user) => {
          let obj = {};
          obj.id = user.userId;
          obj.gy_id = user.gY_User_ID;
          obj.userId = user.userId;
          obj.added = false;
          obj.name = user.userName;
          obj.userName = user.userName;
          obj.email = user.email;
          let count = this.studentsId.find((student) => {
            return student.userGyID === user.gY_User_ID ? student.count : 0;
          });
          obj.courseTaken = count && count.count ? count.count : 0;
          this.items.push(obj);
        });
        if (this.flag == "Edit") {
          this.studentsId.forEach((element) => {
            this.afterSelecting(element.userEmail);
          });
        }
        this.showLoader = false;
      });
    },
    afterSelecting(evt) {
      this.items.sort((a, b) => {
        if (a.name > b.name) return 1;
        else if (a.name < b.name) return -1;
        return 0;
      });

      let index = this.items.findIndex((user) => {
        return user.email == evt;
      });
      let rowItem = this.items[index];
      this.items[index].added = true;
      this.addedUser.push(rowItem);
    },
    assignOrEditCourseToTeam() {
      if (this.addedUser.length < 1) {
        this.showPopup = true;
        this.errorMsg = "Please add at least one user to proceed with this request.";
        this.headText = "";
        return;
      } else {
        this.showPopup = false;
      }
      if (this.flag == "Assign") {
        let arr = [];
        let courses = this.courseDetails;
        this.addedUser.forEach(function (element) {
          courses.forEach(function (course) {
            let assignmentDate = new Date(moment(course.assignmentDate));
            assignmentDate = assignmentDate.getTime();

            let requiredDate = new Date(moment(course.completionDate));
            requiredDate = requiredDate.getTime();

            let obj = {
              componentTypeID: course.itemTypeID,
              componentID: course.itemID,
              revisionDate: course.revisionDate,
              studentId: element.gy_id,
              assignmentDate: assignmentDate,
              requiredDate: requiredDate,
            };
            arr.push(obj);
          });
        });
        let requestPayLoad = { userCourse: arr };
        this.showLoader = true;
        apiCalls
          .httpCallDetails(api.ADDCOURSESTOMYTEAMV2, "post", requestPayLoad)
          .then((data) => {
            console.log("data", data);
            this.showLoader = false;
            this.$router.push("/my-team-learning");
          });
      } else {
        let arr = [];
        let courses = this.courseDetails;
        this.addedUser.forEach(function (element) {
          courses.forEach(function (course) {
            let obj = {
              componentTypeID: course.componentTypeID,
              componentID: course.componentId,
              revisionDate: course.revisionDate,
              studentId: element.gy_id,
            };
            arr.push(obj);
          });
        });
        let requestPayLoad = arr;
        this.showLoader = true;
        apiCalls
          .httpCallDetails(api.ADDCOURSESTOMYTEAM, "post", requestPayLoad)
          .then((data) => {
            this.showLoader = false;
            this.$router.push("/my-team-learning");
          });
      }
    },
    discardAction() {
      if (this.flag == "Assign") {
        this.$router.push({
          name: "AssignLearning",
          query: { assignLearning: "Assign" },
        });
      }
      if (this.flag == "Edit") {
        this.$router.push({
          name: "AssignLearning",
          query: { assignLearning: "Edit" },
        });
      }
    },

    hideModal() {
      this.$refs["my-modal"].hide();
    },
    applyFilter() { },
    resetFilter() { },
    changedFilter() { },
    onSelect(option, index) { },
    onRemove(option, index) { },
    onContextA(ctx, pos) {
      if (ctx.selectedDate) {
        this.courseDetails[pos].assignmentDateInput = ctx.selectedFormatted;
        let temp = this.courseDetails[pos].itemTitle;
        this.courseDetails[pos].itemTitle = " ";
        this.courseDetails[pos].itemTitle = temp;
      }
    },
    onContext(ctx, pos) {
      if (ctx.selectedDate) {
        this.courseDetails[pos].completionDateInput = ctx.selectedFormatted;
        let temp = this.courseDetails[pos].itemTitle;
        this.courseDetails[pos].itemTitle = " ";
        this.courseDetails[pos].itemTitle = temp;
      }
    },
    addUser(rowItem, index) {
      this.items.sort((a, b) => {
        if (a.name > b.name) return 1;
        else if (a.name < b.name) return -1;
        return 0;
      });
      this.items[index].added = true;
      this.addedUser.push(rowItem);
    },
    removeUser(rowItem, index) {
      if (this.flag == "Edit") {
        let arr = [];
        let sId = rowItem.gy_id;

        this.courseDetails.forEach(function (element) {
          let obj = {
            componentTypeID: element.componentTypeID,
            componentID: element.componentId,
            revisionDate: element.revisionDate,
            studentId: sId,
          };
          arr.push(obj);
        });
        let requestPayLoad = arr;
        apiCalls
          .httpCallDetails(api.REMOVECOURSESOFTEAM, "post", requestPayLoad)
          .then((data) => {
          });
      }
      this.addedUser.splice(index, 1);

      this.items.forEach((element) => {
        element.added =
          element.userId === rowItem.userId ? false : element.added;
      });
    },
  },
};
</script>
<style scoped>
.main-con {
  font-family: "BarlowR", sans-serif;
  color: #313131;
  padding: 0;
}

/* new */
.upper-grey-block {
  padding: 2rem;
  border-radius: 0.5rem 0.5rem 0 0;
  font-size: 1.33rem;
  background-color: #f7fcff;
}

.details-btn {
  color: #274ba7;
  background: transparent;
  border: transparent;
  font-family: "BarlowM", sans-serif;
  font-size: 1.166rem;
  padding: 0;
}

.details-btn:hover {
  color: #274ba7 !important;
  background: transparent !important;
  border: transparent !important;
}

.font-16px {
  font-size: 1.33rem;
}

.lable-text {
  background: #f7fcff !important;
  z-index: 1;
}

.save-button {
  background-color: #eff3ff !important;
  font-size: 1.1666666666666667rem;
  font-family: "BarlowR", sans-serif;
  padding: 0.5rem 1rem;
  border-color: #869ed8;
}

.save-button:hover {
  color: #274ba7;
}

.below-table-hr {
  background: #dee2e6;
}

.user-bottom-list {
  border-radius: 20px;
  vertical-align: middle;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #eff3ff !important;
  font-size: 1.1666666666666667rem;
  font-family: "BarlowR", sans-serif;
  border: 1px solid #869ed8;
  margin: 1rem 1rem 0 0;
}

.add-action-footer-info {
  padding-left: 2em !important;
  padding-right: 2em !important;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
}

.discard-btn {
  color: #274ba7;
  background: transparent;
  border: transparent;
  font-family: "BarlowM", sans-serif;
  font-size: 1.166rem;
  margin-right: 2rem;
}

.discard-btn:active {
  color: #274ba7 !important;
  background: transparent !important;
  border: transparent !important;
  font-family: "BarlowM", sans-serif !important;
  font-size: 1.166rem !important;
  margin-right: 2rem !important;
}

.submit-btn {
  height: 3.3rem;
  width: 7.9rem;
  font-family: "BarlowM", sans-serif;
  font-size: 1.166rem;
}

.submit-btn:focus {
  background-color: #274ba7;
}

.funnel-search-box {
  width: 70%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.funnel-con {
  font-size: 1.5rem;
}

.action-btn {
  border-radius: 50px;
  border: 1px solid #274ba7;
  color: #274ba7;
  height: 32px;
  text-align: center;
  width: 32px;
  vertical-align: middle;
  margin-right: 1rem;
}

/* new */

.member-count {
  font-size: 1.083rem;
  font-family: "BarlowSb", sans-serif;
  color: #00790e;
  /* margin-left: 2rem; */
}

.status-last-asmt-con {
  font-size: 1.083rem;
  font-family: "BarlowR", sans-serif;
  padding: 1rem 2rem;
  display: flex;
  justify-content: space-between;
}

.flex-block {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.plant-dd {
  width: 28%;
}

.plant-select {
  height: 54px;
  border-color: #313131;
}

.plant-card {
  background: #fffce3;
  width: 160px;
  height: 54px;
  margin-left: 1.5em;
  text-align: center;
  color: #414141;
  padding: 5px;
  border-radius: 7px;
}

.add-action-btn {
  margin: 1.8rem 0;
  background: #274ba7;
  border-color: #274ba7;
  padding: 0.75rem 1rem;
}

.add-action-btn:hover {
  border-color: #274ba7 !important;
  color: #fff !important;
  background: #274ba7 0% 0% no-repeat padding-box !important;
}

.add-action-btn:active {
  border-color: #274ba7 !important;
  color: #fff !important;
  background: #274ba7 0% 0% no-repeat padding-box !important;
}

.add-action-btn:active:focus {
  box-shadow: 0 0 0 0 rgb(108 117 125 / 50%) !important;
}

.back-btn-con {
  text-align: right;
  padding-right: 3em;
}

.no-data {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  align-items: center;
  height: 8rem;
  justify-content: space-evenly;
}

.top-title {
  font-family: "BarlowM", sans-serif;
}

.status-icons {
  margin: 0px 5px;
  width: 1.66rem;
  height: 1.66rem;
}

.status-name {
  margin-right: 1rem;
  font: 1.08rem "BarlowM", sans-serif;
  color: #313131;
}

@media only screen and (max-width: 767px) {
  .mat-info-con[data-v-dcabda2e] {
    display: block !important;
  }

  .plant-dd[data-v-dcabda2e] {
    width: 100% !important;
  }

  .plant-card[data-v-dcabda2e] {
    width: 100% !important;
    margin-left: 0em !important;
    margin-top: 1em;
  }
}
</style>
<template>
  <div class="search-con">
    <label class="lable-text user-lable" for="input-with-list">User name</label>
    <b-form-input list="input-list" id="input-with-list" class="search-box" v-model="selectedUSer"
      placeholder="Search by user name" @input="findUser"></b-form-input>
    <b-icon icon="search" class="serach-icon" aria-hidden="true"></b-icon>
    <div class="sh-user-con" v-if="serachResult.length > 0">
      <div class="sh-user-list" v-for="user in   serachResult" :key="user.id"
        @click="userSelected(user.id, user.userName, user.email)">
        {{ user.userName }}
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "SearchUser",
  data() {
    return {
      showLoader: false,
      selectedUSer: "",
      serachResult: [],
      selectedUSerId: "",
    };
  },
  props: ['user'],
  methods: {
    findUser() {
      this.serachResult = this.user.filter((user) => {
        return (
          user.userName
            .toLowerCase()
            .includes(this.selectedUSer.toLowerCase()) &&
          this.selectedUSer.toLowerCase()
        );
      });
    },
    userSelected(id, name, email) {
      this.selectedUSer = name;
      this.selectedUSerId = id;
      this.serachResult = [];
      this.$emit('userSelected', email)
    },
  },
};
</script>

<style scoped>
.search-box {
  height: 4rem;
  width: 30%;
  border: 1px solid #313131;
  font-size: 1.166rem;
  font-family: "BarlowR", sans-serif;
}

.search-box:focus {
  border: 2px solid #274ba7;
}

.search-box:focus+.user-lable {
  display: block;
}

.recent-users {
  font-size: 1.333rem;
  font-family: "BarlowM", sans-serif;
  margin-top: 1.5rem;
  margin-bottom: 0.5rem;
}

.user-lable {
  font-size: 1rem;
  font-family: "BarlowM", sans-serif;
}

.serach-icon {
  font-size: 1.5rem;
  position: absolute;
  left: 27%;
  top: 1.2rem;
}

.search-con {
  position: relative;
}

.sh-user-con {
  width: inherit;
  background: #fff;
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
  border-radius: 7px;
  position: absolute;
  z-index: 10;
}

.sh-user-list {
  padding: 1rem;
  border-bottom: 1px solid #f5efef;
  font-size: 1.166rem;
  font-family: "BarlowM", sans-serif;
  cursor: pointer;
}
</style>